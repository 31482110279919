.Dropdown-control {
    background-color: transparent !important;
    min-width: clamp(110px, 30vw, 400px);
    padding: 8px 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #34303D !important;
}
.Dropdown-arrow {
    position: relative !important;
    right: auto !important;
    left: auto !important;
    top: auto !important;
}

.Dropdown-menu {
    border: 1px solid #34303D !important;

}