@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'IQOSLight';
  src: local('IQOSLight'),
  url('fonts/IQOSSans-Light.otf') format('opentype');
}

@font-face {
  font-family: 'IQOSBold';
  src: local('IQOSBold'),
  url('fonts/IQOSSans-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'IQOSRegular';
  src: local('IQOSRegular'),
  url('fonts/IQOSSans-Regular.otf') format('opentype');
}

body {
  margin: 0;
  font-family: IQOSRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  min-height: 100vh;
  max-width: 100%;
}

code {
  font-family: IQOSRegular;
}

:root {
  --primary: #00D1D2;
  --secondary: #34303D;

  --white: #ffffff;
  --gray: #34405C;

  --turqoise: #00D1D2;
  --turqoise-green: #19CBB9;
  --blue: #00A9E8;
  --darker-blue: #267FCD;
  --blue-500: #1D55A2;
  --dark-blue: #005D7E;
  --darkest-blue: #1c0d50;
  --blue-fade: linear-gradient(90deg, #123666 0%, #015D7F 100%);
  --blue-gradient-from: #123666;
  --blue-gradient-to: #015D7F;
}


